<template>
  <el-main>
    <el-form :model="dataInfo.ali" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
      <el-form-item label="支付宝支付：">
        <div class="switchBox">
          <el-switch v-model="dataInfo.open_ali_pay" :active-value="1" :inactive-value="0">
          </el-switch>
          <div class="tips">需要在支付宝商户后台开通当面付，支付通道才有效</div>
        </div>
      </el-form-item>
      <el-form-item v-if="dataInfo.open_ali_pay === 1" label="支付宝AppID：" prop="app_id">
        <el-input v-model="dataInfo.ali.app_id"></el-input>
      </el-form-item>
      <el-form-item v-if="dataInfo.open_ali_pay === 1" label="公钥：" prop="public_key">
        <el-input v-model="dataInfo.ali.public_key"></el-input>
      </el-form-item>
      <el-form-item v-if="dataInfo.open_ali_pay === 1" label="私钥：" prop="private_key">
        <el-input v-model="dataInfo.ali.private_key"></el-input>
      </el-form-item>
    </el-form>
    <el-form :model="dataInfo.wx" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
      <el-form-item label="微信支付：">
        <div class="switchBox">
          <el-switch v-model="dataInfo.open_wechat_pay" :active-value="1" :inactive-value="0">
          </el-switch>
          <div class="tips">需要在微信商户后台开通当面付，支付通道才有效</div>
        </div>
      </el-form-item>
      <el-form-item v-if="dataInfo.open_wechat_pay === 1" label="微信小程序AppID：" prop="app_id">
        <el-input v-model="dataInfo.wx.app_id"></el-input>
      </el-form-item>
      <el-form-item v-if="dataInfo.open_wechat_pay === 1" label="商户号Mch_ID：" prop="public_key">
        <el-input v-model="dataInfo.wx.m_id"></el-input>
      </el-form-item>
      <el-form-item v-if="dataInfo.open_wechat_pay === 1" label="支付秘钥APIkey：" prop="private_key">
        <el-input v-model="dataInfo.wx.api_key"></el-input>
      </el-form-item>
      <el-form-item v-if="dataInfo.open_wechat_pay === 1" label="apiclient_cert：" prop="apiclient_cert">
        <div class="upBox">
          <el-input placeholder="请上传apiclient_key证书" disabled v-model="dataInfo.wx.apiclient_cert">
          </el-input>
          <div class="uploadFile">
            <el-button type="primary">上传文件</el-button>
            <input type="file" ref="inputer0" @change="getFile(0)" />
          </div>
        </div>
      </el-form-item>
      <el-form-item v-if="dataInfo.open_wechat_pay === 1" label="apiclient_key：" prop="apiclient_key：">
        <div class="upBox">
          <el-input placeholder="请上传apiclient_key证书" disabled v-model="dataInfo.wx.apiclient_key">
          </el-input>
          <div class="uploadFile">
            <el-button type="primary">上传文件</el-button>
            <input type="file" ref="inputer1" @change="getFile(1)" />
          </div>
        </div>
      </el-form-item>
    </el-form>
    <Preservation @preservation="preservation"></Preservation>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation'
export default {
  components: {
    Preservation
  },
  data () {
    return {
      dataInfo: {},
      isHave: true,
      id: -1
    }
  },
  created () {
    let that = this
    this.$axios
      .post(this.$api.set.Settinginfo, {
        type: 10
      })
      .then((res) => {
        if (res.code == 0) {
          if (res.result.info == '') {
            that.isHave = false
            that.dataInfo = {
              open_ali_pay: 1,
              open_wechat_pay: 1,
              ali: {
                app_id: '',
                public_key: '',
                private_key: ''
              },
              wx: {
                app_id: '',
                m_id: '',
                api_key: '',
                apiclient_cert: '',
                apiclient_key: ''
              }
            }
            return
          }
          that.id = res.result.info.id
          that.dataInfo = res.result.info.sets
        }
      })
  },
  methods: {
    // 上传文件
    getFile (status) {
      let files = null
      let that = this
      if (status == 0) {
        files = this.$refs.inputer0.files[0]
      } else if (status == 1) {
        files = this.$refs.inputer1.files[0]
      }
      let formData = new FormData()
      formData.append('cert', files)
      this.$axios.uploadFile(this.$api.upload.addCert, formData).then((res) => {
        if (res.code == 0) {
          that.$message.success('上传成功')
          if (status == 0) {
            this.$set(this.dataInfo.wx, 'apiclient_cert', res.result.path)
            this.$refs.inputer0.files[0].value = ''
          } else {
            this.$set(this.dataInfo.wx, 'apiclient_key', res.result.path)
            this.$refs.inputer1.files[0].value = ''
          }
        } else {
          that.$message.error(`${res.msg}`)
        }
      })
    },
    preservation () {
      if (!this.dataInfo.open_ali_pay && !this.dataInfo.open_wechat_pay) {
        this.activeName = 'second'
        this.$message.warning('必须配置一个支付数据')
        return
      }
      if (this.dataInfo.open_ali_pay) {
        if (
          this.dataInfo.ali.app_id == '' ||
          this.dataInfo.ali.public_key == '' ||
          this.dataInfo.ali.private_key == ''
        ) {
          this.activeName = 'second'
          this.$message.warning('请将支付宝支付的所有数据填写完整')
          return
        }
      }
      if (this.dataInfo.open_wechat_pay) {
        if (
          this.dataInfo.wx.app_id == '' ||
          this.dataInfo.wx.m_id == '' ||
          this.dataInfo.wx.api_key == ''
        ) {
          this.activeName = 'second'
          this.$message.warning('请将微信支付的所有数据填写完整')
          return
        }
      }
      let that = this
      let sets = {
        open_ali_pay: this.dataInfo.open_ali_pay,
        open_wechat_pay: this.dataInfo.open_wechat_pay,
        ali: this.dataInfo.ali,
        wx: this.dataInfo.wx
      }
      let url = ''
      let params = {
        sets,
        type: 10
      }
      if (this.isHave) {
        url = this.$api.set.Settingedit
        params.id = this.id
      } else {
        url = this.$api.set.Settingadd
      }
      this.$axios.post(url, params).then((res) => {
        if (res.code == 0) {
          that.$message({
            message: '保存成功',
            type: 'success'
          })
          that.isHave = true
          return
        }
        that.$message({
          message: '保存失败',
          type: 'error'
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-main {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background-color: #fff;

  .el-form {
    .el-form-item {
      width: 900px;

      /deep/ .el-form-item__content .el-input {
        width: 400px;

        .el-button {
          color: #fff !important;
          background-color: #409eff !important;
          border-color: #409eff !important;
          border-radius: 0;
          position: relative;

          input {
            opacity: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
          }
        }
      }
    }
  }
}

.switchBox {
  display: flex;
  align-items: center;

  .tips {
    color: #a3a3a3;
    margin-left: 20px;
    white-space: nowrap;
  }
}

.upBox {
  display: flex;
  align-items: center;
}

.uploadFile {
  position: relative;
  color: #1467ff;
  cursor: pointer;

  &>input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
  }
}
</style>
